<template>
  <base-form
    v-slot="slotProps"
    :model="institution"
    :rules="rules"
    :loading="loading"
    :form-submitted="formSubmitted"
    :submit-label="submitLabel"
    :mode="mode"
    :additional-validators="[
      {
        validator: checkCollections,
        onError: missingCollectionWarning
      }
    ]"
    model-name="Einrichtung"
  >
    <el-form-item label="Einrichtungssammlungen">
      <el-cascader
        v-model="slotProps.model.collection_ids"
        :options="institutionCollectionsForCascader"
        :props="{ multiple: true, checkStrictly: true, emitPath: false }"
        placeholder="Einrichtungssammlungen auswählen"
      ></el-cascader>
    </el-form-item>

    <el-form-item label="Name" prop="name">
      <el-input v-model="slotProps.model.name" v-focus></el-input>
    </el-form-item>

    <el-form-item label="Untertitel" prop="subtitle">
      <el-input
        v-model="slotProps.model.subtitle"
        placeholder="z.B. Zuständigkeit oder Abteilung"
        :maxlength="160"
        show-word-limit
      ></el-input>
    </el-form-item>

    <el-form-item label="Straße und Hausnummer" prop="address">
      <el-input v-model="slotProps.model.address"></el-input>
    </el-form-item>

    <el-form-item label="Postleitzahl" prop="zipcode">
      <el-input v-model="slotProps.model.zipcode"></el-input>
    </el-form-item>

    <el-form-item label="Ort" prop="city">
      <el-input v-model="slotProps.model.city"></el-input>
    </el-form-item>

    <el-form-item label="Telefonnummer" prop="phone">
      <el-input v-model="slotProps.model.phone"></el-input>
    </el-form-item>

    <el-form-item label="E-Mail-Adresse" prop="email">
      <el-input v-model.trim="slotProps.model.email"></el-input>
    </el-form-item>

    <el-form-item label="Website URL" prop="url">
      <el-input
        v-model.trim="slotProps.model.url"
        placeholder="https://www.beispiel.de"
      ></el-input>
    </el-form-item>

    <label class="el-form-item__label">Beschreibung</label>
    <text-editor
      v-model="slotProps.model.description"
      :loading="loading"
      :allow-lists="true"
      :allow-headings="false"
    ></text-editor>

    <el-form-item label="Schlagwörter">
      <tag-input
        v-model="slotProps.model.tags_attributes"
        :options="institutionTags"
        :loading="loading"
        :region-id="$store.getters['auth/getRegionId']"
      />
    </el-form-item>
  </base-form>
</template>

<script>
import GuideItemsRepository from "@/repositories/guide_items_repository.js"
import BaseForm from "@/components/forms/BaseForm"
import InstitutionTagsRepository from "@/repositories/institution_tags_repository.js"
import TagInput from "@/components/TagInput"
import TextEditor from "@/components/TextEditor"
import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"

export default {
  components: {
    BaseForm,
    TagInput,
    TextEditor
  },
  mixins: [ApiErrorHandlerMixin],
  props: {
    institution: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    submitLabel: {
      type: String,
      default: "Änderungen speichern"
    },
    formSubmitted: {
      type: Function,
      required: true
    },
    mode: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loadingGuideItems: true,
      institutionTags: [],
      institutionCollections: [],
      rules: {
        name: [
          { required: true, message: "Bitte Namen angeben", trigger: "blur" }
        ],
        address: [
          { required: true, message: "Bitte Adresse angeben", trigger: "blur" }
        ],
        zipcode: [
          {
            required: true,
            message: "Bitte gültige PLZ angeben",
            trigger: "blur"
          }
        ],
        city: [
          { required: true, message: "Bitte Ort angeben", trigger: "blur" }
        ],
        email: [
          {
            type: "email",
            message: "Bitte gültige E-Mail angeben",
            trigger: "blur"
          }
        ],
        url: [
          {
            type: "url",
            message: "Bitte gültige URL mit https:// oder http:// angeben",
            trigger: "blur"
          }
        ]
      }
    }
  },
  computed: {
    institutionCollectionsForCascader() {
      return this.parseGuideItemTree(this.institutionCollections)
    }
  },
  async created() {
    InstitutionTagsRepository.getAll()
      .then(tags => {
        this.institutionTags = tags
      })
      .catch(error => {
        this.handleApiError(error, "Fehler Laden der Schlagwörter")
      })

    GuideItemsRepository.getAll()
      .then(collections => {
        this.institutionCollections = collections
        this.loadingGuideItems = false
      })
      .catch(error => {
        this.handleApiError(error, "Fehler Laden der Einrichtungssammlungen")
      })
  },
  methods: {
    parseGuideItemTree(collections) {
      let items = []
      let that = this
      collections.forEach(function(cat) {
        if (cat.type == "folder" || cat.type === "institutions") {
          let item = {
            label: cat.name,
            value: cat.collection_id,
            disabled: cat.type === "folder",
            children: that.parseGuideItemTree(cat.children)
          }
          items.push(item)
        }
      })
      return items
    },
    checkCollections() {
      return this.institution.collection_ids.length > 0
    },
    missingCollectionWarning() {
      this.$message({
        message: `Sie müssen mind. eine Einrichtungssammlung auswählen.`,
        type: "error",
        showClose: true
      })
    }
  }
}
</script>
