import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"

export default {
  mixins: [ApiErrorHandlerMixin],
  props: {
    subject: {
      type: String,
      required: true
    },
    onDelete: {
      type: Function,
      required: true
    }
  },
  methods: {
    async handleDelete(item) {
      this.$confirm(
        "Diese Aktion lässt sich nicht rückgängig machen. Sicher?",
        "Achtung",
        {
          confirmButtonClass: "el-button--danger",
          confirmButtonText: "Löschen",
          cancelButtonText: "Abbrechen",
          type: "warning"
        }
      )
        .then(async () => {
          try {
            await this.onDelete(item)
            this.showItemDestroySuccessMessage(this.subject)
          } catch (error) {
            this.handleApiError(error)
          }
        })
        .catch(() => {})
    },
    showItemDestroySuccessMessage(entity) {
      this.$message({
        message: `${entity} wurde erfolgreich gelöscht.`,
        type: "success",
        showClose: true
      })
    }
  },
  render() {
    return this.$scopedSlots.default(this)
  }
}
