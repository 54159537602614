import store from "@/store"
import Repository from "@/repositories/api.js"
const resource = "/admin/institution_tags"

export default {
  async getAll() {
    let response = await Repository.get(resource, {
      params: { region_id: store.state.auth.chosenRegion }
    })
    return response.data.institution_tags
  }
}
