<template>
  <div>
    <the-breadcrumbs :custom-breadcrumbs="breadcrumbs"></the-breadcrumbs>
    <page-content page-title="Einrichtung bearbeiten">
      <institution-form
        :loading="loading"
        :institution="institution"
        :form-submitted="saveInstitution"
        mode="edit"
      ></institution-form>
    </page-content>
    <portal to="fixed-footer-right">
      <delete-button
        v-slot="slotProps"
        :on-delete="deleteInstitution"
        subject="Einrichtung"
      >
        <el-button
          icon="fal fa-trash-alt"
          type="danger"
          @click="slotProps.handleDelete(institution)"
        >
          Löschen
        </el-button>
      </delete-button>
    </portal>
  </div>
</template>

<script>
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"
import InstitutionForm from "@/components/forms/InstitutionForm"
import InstitutionsRepository from "@/repositories/institutions_repository.js"
import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"
import DeleteButton from "@/components/DeleteButton.js"

export default {
  metaInfo: {
    title: "Einrichtung bearbeiten"
  },
  components: {
    TheBreadcrumbs,
    PageContent,
    InstitutionForm,
    DeleteButton
  },
  mixins: [ApiErrorHandlerMixin],
  data() {
    return {
      loading: true,
      institution: {}
    }
  },
  computed: {
    breadcrumbs() {
      return [
        {
          path: "/guide",
          text: "Wissenswertes"
        },
        {
          path: `/institution_collections/${this.$route.query.institution_collection_id}`,
          text: `${this.$route.query.institution_collection_name}`
        }
      ]
    }
  },
  async created() {
    try {
      this.institution = await InstitutionsRepository.get(this.$route.params.id)
      this.loading = false
    } catch (error) {
      this.loading = false
      this.handleApiError(error)
    }
  },
  methods: {
    async saveInstitution(institution) {
      await InstitutionsRepository.update(institution.id, institution)

      try {
        await this.$router.push({
          name: "GuidePage",
          query: { allow_redirect: true }
        })
      } catch {
        //
      }
    },
    async deleteInstitution(institution) {
      await InstitutionsRepository.destroy(institution.id)
      try {
        await this.$router.push({
          name: "GuidePage",
          query: { allow_redirect: true }
        })
      } catch {
        //
      }
    }
  }
}
</script>
